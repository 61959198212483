<template>
  <v-app>
    <SingleAppBar :title="'วิธีสแกนบัตรประชาชน'" backward elevation="0"/>
    <v-main class="primary">
     <v-container>
       <v-row>
          <v-col cols="12">
            <div class="text-center natural--text text-title-3 pb-5">
              นำบัตรประชาชนวางในกรอบ <br>
พร้อมกดปุ่มสแกน
            </div>
          </v-col>
         <v-col cols="12" class="primary darken-2">
            <v-img  class="ma-auto pt-10 pb-15" width="300" contain :src="require('@/assets/samplecard.svg')"></v-img>
         </v-col>
         <v-col cols="12" >
           <v-row class="pt-5 pl-5 pr-5">
             <v-col cols="1">
                <v-icon color="natural">mdi-check-circle</v-icon>
             </v-col>
             <v-col cols="10">
               <div class="text-left natural--text text-title-3">
รูปภาพต้องไม่เบลอเห็นตัวอักษรชัดเจน
และเห็นภาพบัตรเต็มใบ
               </div>
             </v-col>
           </v-row>
            <v-row class="pt-0 pl-5 pr-5">
             <v-col cols="1">
                <v-icon color="natural">mdi-check-circle</v-icon>
             </v-col>
             <v-col cols="10">
               <div class="text-left natural--text text-title-3">
ไม่สแกนวางให้บัตรสะท้อนแสง 
ไม่มืด หรือสว่างเกินไป
               </div>
             </v-col>
           </v-row>
         </v-col>
       </v-row>
     </v-container>
    </v-main>
      <v-footer app class="primary">
      <v-row justify="center" no-gutters>
        <v-btn color="natural primary--text"  x-large height="58" block :to="{ name: 'camera' }">ตกลง</v-btn>
        <v-col cols="12">
          <div class="text-center">
           <v-icon></v-icon>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import SingleAppBar from "@/components/common/SingleAppBar"
export default {
    components: {
        SingleAppBar,
    },

}
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>