export const offlineimage = {
  data() {
      return {
        downloadedImg: null,
        profilPictureName: null
      }
  },
methods: {
     saveImage(url,name){
        let imageURL = url
        this.profilPictureName = name
        this.downloadedImg = new Image;
        this.downloadedImg.crossOrigin = "Anonymous";//Anonymous
        this.downloadedImg.addEventListener("load", this.imageReceived, false);
        this.downloadedImg.src = imageURL;
    },
     imageReceived() {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");
        canvas.width = this.downloadedImg.width;
        canvas.height = this.downloadedImg.height;
        context.drawImage(this.downloadedImg, 0, 0);
        try {
          localStorage.setItem(this.profilPictureName, canvas.toDataURL("image/png"));
        }
        catch(err) {
          console.log("Error: " + err);
        } 
      },
    loadImage(url){
        return localStorage.getItem(url)
    },
    
},

}
