<template>
  <v-card>
      <v-card-text class="text-center mx-auto">
          <div class="pa-6"><v-avatar size="56">
              <v-img :src="require('@/assets/info.svg')"></v-img>
              </v-avatar> </div>
          <div class="primary--text text--darken-4 text-h5">อุปกรณ์ของท่านไม่ได้เชื่อมต่ออิเตอร์เน็ต</div>
          <div class="secondary--text text-title-3">กรุณาเชื่อมต่ออินเตอร์เน็ตก่อนดำเนินการขั้นต่อไป</div>
       
      </v-card-text>
      <v-card-actions>
   <v-row>

              <v-col cols="12" class="pa-2"><v-btn block color="primary" large @click="$emit('onClose')"><span class="text-title-2">ปิด</span></v-btn></v-col>
          </v-row>
      </v-card-actions>
       <v-card-actions></v-card-actions>
  </v-card>
</template>

<script>
export default {

}
</script>


<style lang="scss" scoped>
    .v-btn {
    border-radius: 6px;
    }
     .v-card {
    border-radius: 6px;
    }

</style>